import React, { useState, useEffect, useCallback } from 'react';
import { GoogleMap, LoadScript, Marker, DirectionsRenderer } from '@react-google-maps/api';
import pubnub from '../pubnub';

const containerStyle = {
  width: '100%',
  height: '400px'
};

const center = {
  lat: 22.7196,
  lng: 75.8577
};

const Map = () => {
  const [currentLocation, setCurrentLocation] = useState(null);
  const [otherUserLocation, setOtherUserLocation] = useState(null);
  const [destination, setDestination] = useState({ lat: 22.7533, lng: 75.8937 });
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [otherUserDirectionsResponse, setOtherUserDirectionsResponse] = useState(null);

  useEffect(() => {
    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        const location = { lat: latitude, lng: longitude };
        setCurrentLocation(location);
        pubnub.publish({ channel: 'location-channel', message: { user: 'user1', location } });
      },
      (error) => console.error(error),
      { enableHighAccuracy: true, timeout: 20000, maximumAge: 0 }
    );

    return () => {
      navigator.geolocation.clearWatch(watchId);
    };
  }, []);

  useEffect(() => {
    pubnub.addListener({
      message: (event) => {
        const { user, location } = event.message;
        if (user === 'user1') {
          setCurrentLocation(location);
        } else if (user === 'user2') {
          setOtherUserLocation(location);
        }
      },
    });

    pubnub.subscribe({ channels: ['location-channel'] });

    return () => {
      pubnub.unsubscribe({ channels: ['location-channel'] });
    };
  }, []);

  const fetchDirections = useCallback(() => {
    if (currentLocation && destination && window.google && window.google.maps) {
      const directionsService = new window.google.maps.DirectionsService();

      directionsService.route(
        {
          origin: currentLocation,
          destination: destination,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirectionsResponse(result);
          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      );
    }
  }, [currentLocation, destination]);

  const fetchOtherUserDirections = useCallback(() => {
    if (otherUserLocation && destination && window.google && window.google.maps) {
      const directionsService = new window.google.maps.DirectionsService();

      directionsService.route(
        {
          origin: otherUserLocation,
          destination: destination,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setOtherUserDirectionsResponse(result);
          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      );
    }
  }, [otherUserLocation, destination]);

  useEffect(() => {
    if (currentLocation) fetchDirections();
    if (otherUserLocation) fetchOtherUserDirections();
  }, [fetchDirections, fetchOtherUserDirections]);

  const renderMarkers = useCallback(() => {
    return (
      <>
        {currentLocation && (
          <Marker
            position={currentLocation}
            icon={{
              url: '/car-icon.png',
              scaledSize: new window.google.maps.Size(50, 30),
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(25, 15)
            }}
            label="You"
          />
        )}
        {destination && <Marker position={destination} label="Destination" />}
        {otherUserLocation && (
          <Marker
            position={otherUserLocation}
            icon={{
              url: '/car-icon.png',
              scaledSize: new window.google.maps.Size(50, 30),
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(25, 15)
            }}
            label="User 2"
          />
        )}
      </>
    );
  }, [currentLocation, destination, otherUserLocation]);

  return (
    <LoadScript googleMapsApiKey="AIzaSyA7h5UtxhqkIcMY4po5Qg8aitnXgEd2gCU">
      {currentLocation?.lat && currentLocation?.lng ? (
        <p>
          Latitude: {currentLocation.lat}, Longitude: {currentLocation.lng}
        </p>
      ) : (
        <p>Loading...</p>
      )}
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
        {renderMarkers()}
        {directionsResponse && (
          <DirectionsRenderer
            directions={directionsResponse}
            options={{
              polylineOptions: {
                strokeColor: "#0000FF"
              }
            }}
          />
        )}
        {otherUserDirectionsResponse && (
          <DirectionsRenderer
            directions={otherUserDirectionsResponse}
            options={{
              polylineOptions: {
                strokeColor: "#FF0000"
              }
            }}
          />
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default Map;
