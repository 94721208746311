// src/App.js
import React from 'react';
import './App.css';
// import LocationShare from './components/LocationShare';
import Map from './components/Map';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <h1>Real-Time Location Sharing</h1>
        <LocationShare /> */}
        <Map />
      </header>
    </div>
  );
}

export default App;
