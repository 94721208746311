// src/pubnub.js
import PubNub from 'pubnub';

const uuid = () => '_' + Math.random().toString(36).substr(2, 9);

const pubnub = new PubNub({
  publishKey: 'pub-c-12eca3d3-229b-4be0-87f2-285f0b9798eb',
  subscribeKey: 'sub-c-3263bcc6-69cd-11ea-be06-76a98e4db888',
  uuid: uuid()
});

export default pubnub;
